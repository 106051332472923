// @ts-check
import toggle from '@bottlebooks/gatsby-theme-event/src/components/Filters/helpers/toggleArrayElement';
import baseReducer, {
  initialState as baseInitialState,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/productFilterReducer';

export * from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/productFilterReducer';

const CUSTOMER_SEGMENT_TOGGLED = 'CUSTOMER_SEGMENT_TOGGLED';
const FILTERS_CLEARED = 'FILTERS_CLEARED';

export function customerSegmentToggled(value) {
  return [CUSTOMER_SEGMENT_TOGGLED, value];
}

export const initialState = {
  ...baseInitialState,
  customerSegments: [],
};

export default function reducer(rawState, event) {
  const state = baseReducer(rawState, event);
  const [type, payload] = event;
  switch (type) {
    case CUSTOMER_SEGMENT_TOGGLED: {
      return {
        ...state,
        customerSegments: toggle(state.customerSegments, payload),
        currentOffset: 0,
      };
    }
    case FILTERS_CLEARED: {
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return state;
  }
}
