import incCount from '@bottlebooks/gatsby-theme-event/src/components/Filters/helpers/incCount';
import get from 'lodash/get';

import orderBy from 'lodash/orderBy';
import stringIsEqual from '@bottlebooks/gatsby-theme-event/src/components/Filters/helpers/stringIsEqual';

const LABELS = {
  IMPORTERS_HIGHLIGHTS: 'In Importers Highlights Tasting',
  EXPORTERS_TASTING: 'In Exporters Tasting',
};

export default function createFacet(_config, state) {
  return {
    // Define the state that is used for filtering the products before reducing to individual values.
    state: { ...state, highlights: [] },

    // The reducer for the facet values.
    reducer: (highlights, product) => {
      const highlightValues = get(product, 'highlights.values');
      const highlightsReduced =
        highlightValues?.reduce(
          (acc, value) => incCount(acc, value),
          highlights
        ) || highlights;
      const currentlyAvailableInUkRetail = incCount(
        highlightsReduced,
        product.currentlyAvailableInUkRetail?.value
          ? 'Available in UK Retail'
          : undefined
      );
      const thisIsANewProductForTheUkIn2021 = incCount(
        currentlyAvailableInUkRetail,
        product.thisIsANewProductForTheUkIn2021?.value
          ? 'New for 2021'
          : undefined
      );
      const thisWineIsPartOfTheDigitalTastingExporter = incCount(
        thisIsANewProductForTheUkIn2021,
        ['yes'].includes(product.thisWineIsPartOfTheDigitalTasting?.rawText)
          ? LABELS.EXPORTERS_TASTING
          : undefined
      );
      const thisWineIsPartOfTheDigitalTastingImporter = incCount(
        thisWineIsPartOfTheDigitalTastingExporter,
        [
          'yesImportersHighlightsTradeTasting',
          'yesSpanishWinesInRetailPressTasting',
        ].includes(product.thisWineIsPartOfTheDigitalTasting?.rawText)
          ? LABELS.IMPORTERS_HIGHLIGHTS
          : undefined
      );
      return thisWineIsPartOfTheDigitalTastingImporter;
    },

    // The initial value for the reducer.
    // If a filter is set on the productTypes: keep that value in the facets even with 0 results.
    initialValue: state.productTypes.reduce((initialValue, productType) => {
      initialValue[productType] = 0;
      return initialValue;
    }, {}),

    // Sort and group the facets before displaying them.
    prepare: (facetValues) => {
      return orderBy(
        Object.entries(facetValues)
          .filter(([value]) => Boolean(value))
          .map(([value, count]) => ({ value, count })),
        'count',
        'desc'
      );
    },

    // Tests if a product matches a certain filter.
    matches: ({ highlights }) => (product) => {
      if (!highlights || highlights.length === 0) return true;
      return highlights.some((highlight) => {
        if (
          stringIsEqual(
            product.currentlyAvailableInUkRetail?.value
              ? 'Available in UK Retail'
              : undefined,
            highlight
          )
        )
          return true;
        if (
          stringIsEqual(
            product.thisIsANewProductForTheUkIn2021?.value
              ? 'New for 2021'
              : undefined,
            highlight
          )
        )
          return true;
        if (
          stringIsEqual(
            ['yes'].includes(product.thisWineIsPartOfTheDigitalTasting?.rawText)
              ? LABELS.EXPORTERS_TASTING
              : undefined,
            highlight
          )
        )
          return true;
        if (
          stringIsEqual(
            [
              'yesImportersHighlightsTradeTasting',
              'yesSpanishWinesInRetailPressTasting',
            ].includes(product.thisWineIsPartOfTheDigitalTasting?.rawText)
              ? LABELS.IMPORTERS_HIGHLIGHTS
              : undefined,
            highlight
          )
        )
          return true;

        const values = product?.highlights?.values;
        if (!values?.length) return false;

        return values.includes(highlight);
      });
    },
  };
}
