/** @jsx jsx */
import DefaultFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/DefaultFacet/DefaultFacet';
// import { Plural } from '@lingui/macro';
import { jsx } from 'theme-ui';

export default function ProductCustomerSegmentFacet({
  allValues,
  selectedValues,
  onToggle,
  totalCount,
  count,
  ...rest
}) {
  return (
    <DefaultFacet
      // title={
      //   <Plural value={allValues?.length} one="# segment" other="# segments" />
      // }
      title={
        allValues?.length === 1 ? '1 segment' : `${allValues.length} segments`
      }
      allValues={allValues}
      selectedValues={selectedValues}
      onToggle={onToggle}
      totalCount={totalCount}
      count={count}
      {...rest}
    />
  );
}
