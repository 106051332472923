/** @jsx jsx */
// @ts-check
import { Box } from '@bottlebooks/gatsby-theme-base';
import ProductCharacteristicsFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductCharacteristicsFacet';
import ProductClosureTypeFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductClosureTypeFacet';
import ProductDenominationFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductDenominationFacet';
import {
  blendTypeToggled,
  characteristicsToggled,
  closureTypeToggled,
  // subregionToggled,
  denominationToggled,
  highlightToggled,
  mainVarietyToggled,
  priceChanged,
  productTypeToggled,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/productFilterReducer';
import ProductHighlightsFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductHighlightsFacet';
import ProductPriceFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductPriceFacet';
// import ProductSubregionFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductSubregionFacet';
import ProductTypeFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductTypeFacet';
import ProductVarietiesFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductVarietiesFacet';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import ProductCustomerSegmentFacet from './ProductCustomerSegmentFacet';
import { customerSegmentToggled } from './productFilterReducer';

export default function ProductFilters({
  facets,
  dispatch,
  state,
  totalCount,
  count,
  ...rest
}) {
  return (
    <Box {...rest}>
      <ProductCustomerSegmentFacet
        allValues={facets.customerSegment}
        selectedValues={state.customerSegments}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(customerSegmentToggled(value), isActive)
        }
      />
      <ProductHighlightsFacet
        allValues={facets.highlight}
        selectedValues={state.highlights}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(highlightToggled(value), isActive)
        }
      />
      <ProductCharacteristicsFacet
        allValues={facets.characteristics}
        selectedValues={state.characteristics}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(characteristicsToggled(value), isActive)
        }
      />
      {/* <ProductSubregionFacet
        allValues={facets.subregion}
        selectedValues={state.subregions}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(subregionToggled(value), isActive)
        }
      /> */}
      <ProductDenominationFacet
        allValues={facets.denomination}
        selectedValues={state.denominations}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(denominationToggled(value), isActive)
        }
      />
      <ProductVarietiesFacet
        allMainVarieties={facets.mainVariety}
        selectedMainVarieties={state.mainVarieties}
        allBlendTypes={facets.blendType}
        selectedBlendTypes={state.blendTypes}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(mainVarietyToggled(value), isActive)
        }
        onToggleBlendType={(value, isActive) => {
          dispatch(blendTypeToggled(value), isActive);
        }}
      />
      <ProductTypeFacet
        allValues={facets.productType}
        selectedValues={state.productTypes}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(productTypeToggled(value), isActive)
        }
      />

      {facets.price && (
        <ProductPriceFacet
          values={state.price}
          onChange={(values) => dispatch(priceChanged(values))}
        />
      )}

      <ProductClosureTypeFacet
        allValues={facets.closureTypes}
        selectedValues={state.closureTypes}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(closureTypeToggled(value), isActive)
        }
      />
    </Box>
  );
}

export const fragment = graphql`
  fragment custom_ProductFilters on Bottlebooks_RegisteredProduct {
    customerSegments: customFieldValue(key: "customerSegment") {
      ...FieldValue
    }
    highlights: customFieldValue(key: "highlights") {
      ...FieldValue
    }
    currentlyAvailableInUkRetail: customFieldValue(
      key: "currentlyAvailableInUkRetail"
    ) {
      ...FieldValue
    }
    thisIsANewProductForTheUkIn2021: customFieldValue(
      key: "thisIsANewProductForTheUkIn2021"
    ) {
      ...FieldValue
    }
    thisWineIsPartOfTheDigitalTasting: customFieldValue(
      key: "thisWineIsPartOfTheDigitalTasting"
    ) {
      rawText
    }
  }
`;
