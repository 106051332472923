import incCount from '@bottlebooks/gatsby-theme-event/src/components/Filters/helpers/incCount';
import get from 'lodash/get';

import orderBy from 'lodash/orderBy';
import stringIsEqual from '@bottlebooks/gatsby-theme-event/src/components/Filters/helpers/stringIsEqual';

export default function createFacet(_config, state) {
  return {
    // Define the state that is used for filtering the products before reducing to individual values.
    state: { ...state, customerSegments: [] },

    // The reducer for the facet values.
    reducer: (customerSegments, product) => {
      const customerSegmentsValues = get(product, 'customerSegments.values');
      const customerSegmentsReduced =
        customerSegmentsValues?.reduce((acc, value) => {
          // Group customer segments
          // const segments = [
          //   'Primarily to small and independent off-trade',
          //   'Primarily to online retail / mail order',
          //   'Primarily to on-trade',
          //   'Primarily to multiple off-trade',
          // ];
          // if (
          //   // customerSegmentsValues.length === 1 &&
          //   value === 'All customer segments'
          // ) {
          //   segments.map((segment) => {
          //     return incCount(acc, segment);
          //   });
          //   return acc;
          // } else return incCount(acc, value);

          // Exclude customer segments
          // if (value !== 'All customer segments') resturn incCount(acc, value);
          return incCount(acc, value);
        }, customerSegments) || customerSegments;
      return customerSegmentsReduced;
    },

    // The initial value for the reducer.
    // If a filter is set on the productTypes: keep that value in the facets even with 0 results.
    initialValue: state.productTypes.reduce((initialValue, productType) => {
      initialValue[productType] = 0;
      return initialValue;
    }, {}),

    // Sort and group the facets before displaying them.
    prepare: (facetValues) => {
      return orderBy(
        Object.entries(facetValues)
          .filter(([value]) => Boolean(value))
          .map(([value, count]) => ({ value, count })),
        'count',
        'desc'
      );
    },

    // Tests if a product matches a certain filter.
    matches: ({ customerSegments }) => (product) => {
      if (!customerSegments || customerSegments.length === 0) return true;
      return customerSegments.some((customerSegment) => {
        if (stringIsEqual(product.customerSegments?.value, customerSegment))
          return true;

        const values = product?.customerSegments?.values;
        if (!values?.length) return false;

        return values.includes(customerSegment);
      });
    },
  };
}
