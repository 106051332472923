/** @jsx jsx */
import { Box, Button } from '@bottlebooks/gatsby-theme-base';
import FacetValue from '@bottlebooks/gatsby-theme-event/src/components/Filters/DefaultFacet/FacetValue';
import { jsx } from 'theme-ui';
import {
  awardToggled,
  characteristicsToggled,
  classificationToggled,
  countryToggled,
  customerSegmentToggled,
  denominationToggled,
  designationToggled,
  filtersCleared,
  grapeVarietyToggled,
  highlightToggled,
  mainSoilTypeToggled,
  mainVarietyToggled,
  nameToggled,
  priceChanged,
  productTypeToggled,
  regionNameToggled,
  regionToggled,
  searchTermChanged,
  subregionToggled,
  vintageToggled,
} from './productFilterReducer';

/**
 * The facets bar on the top of the page. Selected facet values are shown here.
 * These shouldn't need to be customized – all the possible facets should be included though.
 * @param {*} param0
 */
export default function ProductFacetsBar({
  state: {
    searchTerm,
    productTypes,
    countries,
    regions,
    subregions,
    regionNames,
    denominations,
    vintages,
    grapeVarieties,
    mainVarieties,
    names,
    characteristics,
    designations,
    classifications,
    price,
    mainSoilTypes,
    awards,
    customerSegments,
    closureTypes,
    highlights,
  },
  dispatch,
  ...rest
}) {
  const filters = [
    searchTerm,
    ...productTypes,
    ...countries,
    ...subregions,
    ...countries,
    ...regions,
    ...subregions,
    ...regionNames,
    ...denominations,
    ...vintages,
    ...grapeVarieties,
    ...mainVarieties,
    ...names,
    ...characteristics,
    ...designations,
    ...classifications,
    ...price,
    ...mainSoilTypes,
    ...awards,
    ...customerSegments,
    ...closureTypes,
    ...highlights,
  ].filter(Boolean);
  if (filters.length === 0) return null;
  return (
    <Box sx={{ padding: 3 }} {...rest}>
      <Box sx={{ marginBottom: -3 }}>
        {searchTerm && (
          <FacetValue
            isSelected
            onClick={() => dispatch(searchTermChanged(''))}
            variant="small"
          >
            {searchTerm}
          </FacetValue>
        )}
        {price?.filter(Boolean).length > 0 && (
          <FacetValue
            isSelected
            onClick={() => dispatch(priceChanged([undefined, undefined]))}
            variant="small"
          >
            {price[0]}–{price[1]}
          </FacetValue>
        )}

        {productTypes.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(productTypeToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {customerSegments.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(customerSegmentToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {mainSoilTypes.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(mainSoilTypeToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {awards.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(awardToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {characteristics.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(characteristicsToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {classifications.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(classificationToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {designations.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(designationToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {countries.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(countryToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {regions.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(regionToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {subregions.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(subregionToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {regionNames.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(regionNameToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {denominations.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(denominationToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {vintages.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(vintageToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {grapeVarieties.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(grapeVarietyToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {mainVarieties.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(mainVarietyToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {names.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(nameToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {closureTypes.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(closureTypeToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {highlights.map((value) => (
          <FacetValue
            key={value}
            isSelected
            onClick={() => dispatch(highlightToggled(value))}
            variant="small"
          >
            {value}
          </FacetValue>
        ))}
        {filters.length > 1 && (
          <Button
            variant="text"
            onClick={() => dispatch(filtersCleared())}
            sx={{ color: 'primary', variant: 'label.default' }}
          >
            Clear all filters
          </Button>
        )}
      </Box>
    </Box>
  );
}
