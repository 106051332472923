/** @jsx jsx */
import { gql } from '@apollo/client';
import { Flex, Text } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import ListRow from '@bottlebooks/gatsby-theme-event/src/components/List/ListRow';
import ListRowBody from '@bottlebooks/gatsby-theme-event/src/components/List/ListRowBody';
import ProductBadge from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductBadge';
import ProductHeader, {
  ProductHeaderFragment,
} from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductHeader';
import ProductImage, {
  ProductImageFragment,
} from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductImage';

export default function ProductListRow({ product, ...rest }) {
  const availableInUk = !!product.currentlyAvailableInUkRetail?.value;
  const hasStockistDetails = !!product.listOfStockists?.text;
  return (
    <ListRow sx={{ alignItems: 'center' }} {...rest}>
      <ProductListRowImage product={product} />
      <ListRowBody sx={{ position: 'relative', marginBottom: 3 }}>
        <ProductHeader product={product} />
        {availableInUk && hasStockistDetails && (
          <Text variant="smallest" sx={{ color: '#333' }}>
            {product.listOfStockists.text}
          </Text>
        )}
        {availableInUk && !hasStockistDetails && (
          <Text variant="smallest" sx={{ color: '#333' }}>
            Contact exhibitor for stockists
          </Text>
        )}
      </ListRowBody>

      <ProductFeatures product={product} />
    </ListRow>
  );
}

export function ProductListRowImage({ product }) {
  return <ProductImage product={product} sx={{ margin: 3, marginRight: 0 }} />;
}

function ProductFeatures({ product }) {
  if (!product.features?.length) return null;
  return (
    <Flex
      sx={{
        width: '100%',
        paddingRight: 2,
        paddingBottom: 2,
        alignSelf: 'flex-end',
        position: 'relative', // Needed to stay above image backgrounds
        marginLeft: 'auto',
        justifyContent: 'flex-end',
      }}
    >
      {product.features?.map((type) => (
        // Product features have to be queried separately by the parent.
        <ProductBadge
          key={type}
          variant="onPrimary"
          type={type}
          sx={{ marginTop: -3 }}
        />
      ))}
    </Flex>
  );
}

// HEADSUP: Product features have to be queried separately by the parent.

export const fragment = graphql`
  fragment bb_ProductListRow on Bottlebooks_Product {
    ...bb_ProductHeader
  }
  fragment ProductListRow on Product {
    ...ProductImage_list
    ...ProductHeader
  }

  # A fragment for the first page of products. It has more detailed images.
  fragment ProductListRow_first on Product {
    ...ProductImage
  }
  fragment custom_ProductSections on Bottlebooks_RegisteredProduct {
    listOfStockists: customFieldValue(key: "listOfStockists") {
      ...FieldValue
    }
    currentlyAvailableInUkRetail: customFieldValue(
      key: "currentlyAvailableInUkRetail"
    ) {
      ...FieldValue
    }
  }
`;

ProductListRow.fragment = gql`
  fragment ProductListRow on Product {
    ...ProductImage
    ...ProductHeader
  }
  ${ProductImageFragment}
  ${ProductHeaderFragment}
`;
