import createBaseFacets from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/productFacets';
import createCustomerSegments from './product.customerSegments';

/**
 * Facet creation. This will be called every time the state or config changes (basically on every rerender).
 * This file is meant to be customized per client - just return the filters that are used.
 * @param {object} config possible configuration from the ProductPage.
 * @param {object} state The current filter state from the productFilterReducer.
 */
export default function createFacets(config, state) {
  const { ...baseFacets } = createBaseFacets(config, state);
  return {
    ...baseFacets,
    customerSegment: createCustomerSegments(config, state),
  };
}
