/** @jsx jsx */
import ProductsPage from '@bottlebooks/gatsby-theme-event/src/components/ProductsPage/ProductsPage';
import { LinkProvider } from '@bottlebooks/gatsby-theme-event/src/useLink';
import { graphql } from 'gatsby';
import deepMerge from 'lodash/merge';
import { jsx } from 'theme-ui';

export default function ProductsTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { event, site, products } = afterQuery(data);
  const { filterPriceRange } = event || {};
  return (
    <LinkProvider value={pageContext.paths}>
      <ProductsPage
        {...pageContext}
        // Make sure the page is remounted on a different href (i.e. a different query).
        // QUESTION: Why is this actually necessary? Because of the page's state?
        key={location.href}
        event={event}
        products={products}
        siteTitle={site?.siteMetadata?.title}
        facetConfig={{
          filterPriceRange,
          ...pageContext,
        }}
        location={location}
        navigate={navigate}
      />
    </LinkProvider>
  );
}

function afterQuery({ bottlebooks, firstProducts, products, site }) {
  const importersProducts = bottlebooks?.importers?.registeredProducts?.nodes;
  const exportersProducts = bottlebooks?.exporters?.registeredProducts?.nodes;
  const combinedProducts = products.nodes?.map((product, index) => {
    const refinedData = firstProducts?.nodes[index];
    const importers_product = importersProducts?.find(
      ({ productId }) => productId === product.productId
    );
    const exporters_product = exportersProducts?.find(
      ({ productId }) => productId === product.productId
    );
    return deepMerge(
      {},
      product,
      importers_product,
      importers_product?.product,
      exporters_product, // the registeredProduct
      exporters_product?.product, // The actual product
      refinedData
    );
  });

  return {
    event: bottlebooks?.theme,
    site,
    products: combinedProducts,
  };
}

export const pageQuery = graphql`
  query custom_ProductsTemplate(
    $locale: Bottlebooks_ContentLocale
    $filter: ProductFilterInput
    $sort: ProductSortInput
    $localeString: String
  ) {
    bottlebooks {
      theme: event(eventId: "6048b4376a7c621a467c85d5", locale: $locale) {
        ...custom_ProductTemplate_Fragment
      }
      importers: event(eventId: "6033a2d31d00c321389bf5f7", locale: $locale) {
        ...custom_ProductTemplate_Fragment
      }
      exporters: event(eventId: "603f54d910828b19f691ff46", locale: $locale) {
        ...custom_ProductTemplate_Fragment
      }
    }

    firstProducts: allProduct(filter: $filter, sort: $sort, limit: 10) {
      nodes {
        ...ProductsPage_first
      }
    }
    products: allProduct(filter: $filter, sort: $sort) {
      nodes {
        productId
        slug
        exhibitor {
          slug
        }
        ...ProductsPage
      }
    }
    event(locale: { eq: $localeString }) {
      ...ProductsPage_Event
      filterPriceRange
    }
    site {
      siteMetadata {
        title
      }
    }
  }

  fragment custom_ProductTemplate_Fragment on Bottlebooks_Event {
    registeredProducts {
      nodes {
        productId
        ...custom_ProductFilters
        ...custom_ProductSections
        product {
          ...custom_ProductRegion
          ...bb_ProductsPageBase
        }
      }
    }
  }
`;
